import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import { fetchLanguages, fetchOptLanguages, hasWindow } from '../../utils'
import { getDoNotTrack, setUserId } from './utils'

// TODO: config based on dev/prod/test
// this is stag config
const firebaseConfig = {
  apiKey: 'AIzaSyC3YOjYSPd7Y2Cnw21HPrMeMQmAXgnn0E8',
  authDomain: 'datari-staging.firebaseapp.com',
  databaseURL: 'https://datari-staging.firebaseio.com',
  projectId: 'datari-staging',
  storageBucket: 'datari-staging.appspot.com',
  messagingSenderId: '1052807466236',
  appId: '1:1052807466236:web:e9c695b6462f8b127a1b79',
}

firebase.initializeApp(firebaseConfig)

export const getCurrentUser = () => {
  if (!hasWindow()) {
    return
  }
  return firebase.auth().currentUser
}

export const signInAnonymously = async () => {
  if (!hasWindow()) {
    return
  }
  try {
    await firebase.auth().signInAnonymously()
    setUserId(firebase.auth().currentUser?.uid)
    // TODO: fetch available languages
    // const languagesResponse = await fetchLanguages()
    // const optLanguagesResponse = await fetchOptLanguages()
    // const languages = await languagesResponse?.json()
    // const optLanguages = await optLanguagesResponse?.json()
    // console.log('languages', languages)
    // console.log('optLanguages', optLanguages)
  } catch (error) {
    console.error(error)
  }
}

export const signOut = async () => {
  if (!hasWindow()) {
    return
  }
  try {
    await firebase.auth().signOut()
    setUserId(undefined)
  } catch (error) {
    console.log(error)
  }
}

export const initialSignIn = async () => {
  if (!hasWindow()) {
    return
  }
  try {
    const doNotTrack = getDoNotTrack()
    if (!doNotTrack && !firebase.auth().currentUser) {
      await signInAnonymously()
    }
  } catch (error) {
    console.log(error)
  }
}
