import { getCurrentUser } from './components/auth/firebase'
import { getUserId } from './components/auth/utils'
import { getLocale } from './components/i18n/utils'

export type Maybe<T> = T | undefined | null

export const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
}

export const hasWindow = () => typeof window !== 'undefined'

export const isProd = () => process.env.NODE_ENV === 'production'

export const isNil = <T>(val: T): boolean => val === undefined || val === null

export const getNumberOrZero = (str: Maybe<string>) => {
  if (typeof str !== 'string') {
    return 0
  }

  const num = parseInt(str)
  return isNaN(num) ? 0 : num
}

export const fetchPost = async (url: string, data: any) => {
  const token = await getCurrentUser()?.getIdToken()

  const headers: Headers = new Headers({
    'Content-Type': 'application/json',
  })
  if (!!token) {
    headers.append('Authorization', `Bearer ${token}`)
  }

  // console.log('fetching', url, data, !!token)

  return await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers,
    redirect: 'follow',
    referrer: 'no-referrer',
    body: JSON.stringify(data),
  })
}

export const fetchOptLanguages = async () => {
  const token = await getCurrentUser()?.getIdToken()
  const uid = getUserId()

  if (!token || !uid) {
    return
  }

  return fetch(`/api/v2/users/${uid}/profile/options.client-language`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    redirect: 'follow',
    referrer: 'no-referrer',
  })
}
export const fetchLanguages = async () => {
  const token = await getCurrentUser()?.getIdToken()
  const uid = getUserId()

  if (!token || !uid) {
    return
  }

  return fetch(`/api/v2/users/${uid}/profile/client-language`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    redirect: 'follow',
    referrer: 'no-referrer',
  })
}

export interface FAQType {
  id: string
  question: string
  answer: string
}

export const fetchFaq = async (): Promise<FAQType[]> => {
  const lang = getLocale()

  const response = await fetch(`/api/web/faq?lang=${lang}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrer: 'no-referrer',
  })
  const body = await response.json()

  return (body as any[]).map((faqResponse, index) => ({
    id: faqResponse.id || index,
    question: faqResponse.title || '',
    answer: faqResponse.description_text || '',
  }))
}

export const calculateElementWidth = (el: Element, includeMargins?: boolean): number => {
  const { width } = el.getBoundingClientRect()
  if (!includeMargins) {
    return width
  }

  const { marginLeft, marginRight } = window.getComputedStyle(el)
  return getNumberOrZero(marginLeft) + getNumberOrZero(marginRight) + width
}

interface IsMobileResponse {
  isMobile: boolean
  isIOS: boolean
  isAndroid: boolean
}

/**
 * Determine the mobile operating system.
 */
export const isMobile = (): IsMobileResponse => {
  if (!hasWindow()) {
    return {
      isMobile: false,
      isAndroid: false,
      isIOS: false,
    }
  }
  const userAgent = navigator.userAgent || navigator.vendor

  const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream
  const isAndroid = /android/i.test(userAgent)

  return {
    isMobile: isIOS || isAndroid,
    isIOS,
    isAndroid,
  }
}

export enum MarketType {
  appStore = 'appstore',
  googlePlay = 'googleplay',
}

export const getMarketType = (): MarketType | undefined => {
  const { isIOS, isAndroid } = isMobile()
  if (isIOS) {
    return MarketType.appStore
  } else if (isAndroid) {
    return MarketType.googlePlay
  }
  return undefined
}
