import cookies, { CookieAttributes } from 'js-cookie'
import psl, { ParsedDomain } from 'psl'
import { hasWindow } from '../../utils'
import { signInAnonymously, signOut } from './firebase'

const DO_NOT_TRACK_KEY = 'do-not-track'
const VISITOR_ID = 'visitor-id'

export const setDoNotTrack = (doNotTrack: boolean): void => {
  if (!hasWindow()) {
    return
  }

  const opts: CookieAttributes = {}
  const { domain } = psl.parse(window.location.hostname) as ParsedDomain
  if (domain) {
    opts.domain = domain
  }

  cookies.set(DO_NOT_TRACK_KEY, JSON.stringify(doNotTrack), opts)

  if (doNotTrack) {
    signOut()
  } else {
    signInAnonymously()
  }
}

export const getDoNotTrack = (): boolean => {
  const doNotTrackCookie = cookies.get(DO_NOT_TRACK_KEY)
  if (!doNotTrackCookie) {
    const browserDoNotTrackSetting = hasWindow() ? navigator.doNotTrack === '1' : false
    setDoNotTrack(browserDoNotTrackSetting)
    return browserDoNotTrackSetting
  }
  return JSON.parse(doNotTrackCookie)
}

export const setUserId = (uid: string | undefined): void => {
  if (!hasWindow()) {
    return
  }

  if (!uid) {
    cookies.remove(VISITOR_ID)
    return
  }

  const opts: CookieAttributes = {}
  const { domain } = psl.parse(window.location.hostname) as ParsedDomain
  if (domain) {
    opts.domain = domain
  }

  cookies.set(VISITOR_ID, JSON.stringify(uid), opts)
}

export const getUserId = (): string | undefined => cookies.get(VISITOR_ID)
