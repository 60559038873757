const messages: any = {}
const locales: string[] = []

export const defaultLocale = 'en'

function importAllLocales(r: __WebpackModuleApi.RequireContext) {
  for (const key of r.keys()) {
    const locale = key.split('/')[1]
    if (locale === 'sources') {
      continue
    }

    messages[locale] = r(key)
    locales.push(locale)
  }
}

// TODO load only locales needed for current page to save bundle size
// Loads all json files in locales folder and passes it to `importAllLocales` fn
importAllLocales(require.context('../../../locales', true, /\.json$/))

export const getLocales = () => locales
export const getMessages = () => messages
