import { flatten } from 'flat'
import React, { ComponentType, PureComponent } from 'react'
import { injectIntl, IntlContext, IntlProvider } from 'react-intl'
import { getMessages } from './locales'
import { setLocaleToCookie, getLocale } from './utils'
import LocaleContext from './locale-context'

const InjectIntlContext = injectIntl(({ intl, children }) => {
  return <IntlContext.Provider value={intl}>{children}</IntlContext.Provider>
})

const withIntl = <T extends object>(Wrapped: ComponentType<T>) => {
  class WithIntl extends PureComponent<T> {
    public state = {
      locale: getLocale(),
    }

    public componentDidMount() {
      setLocaleToCookie(this.state.locale)
    }

    public render() {
      const { locale } = this.state
      const messages = getMessages()
      const flattenMsgs: any = flatten(messages[locale] || {})

      const setLocale = (loc: string) => {
        setLocaleToCookie(loc)
        this.setState({ locale: loc })
      }

      return (
        <LocaleContext.Provider value={{ locale, setLocale }}>
          <IntlProvider
            locale={locale}
            messages={flattenMsgs}
            // HACK https://github.com/formatjs/react-intl/issues/465#issuecomment-527634135
            onError={() => {}}
          >
            {
              // @ts-ignore // TODO
              <InjectIntlContext>
                <Wrapped {...this.props} />
              </InjectIntlContext>
            }
          </IntlProvider>
        </LocaleContext.Provider>
      )
    }
  }

  return WithIntl
}

export default withIntl
