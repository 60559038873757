import cookies, { CookieAttributes } from 'js-cookie'
import { IntlFormatters } from 'react-intl'
import { hasWindow } from '../../utils'
import { defaultLocale, getLocales } from './locales'
import psl, { ParsedDomain } from 'psl'

const LOCALE_KEY = 'locale'

const normalizeLocale = (locale: string): string => {
  const [localeShort] = locale.split('-')
  return localeShort.toLowerCase()
}

const isLocaleSupported = (locale: string): boolean => !!getLocales().find(l => l === locale)

const getBrowserLocale = (): string | undefined => {
  const locale = (navigator.languages && navigator.languages[0]) || navigator.language
  return locale ? normalizeLocale(locale) : undefined
}

const getLocaleFromCookie = (): string | undefined => {
  const locale = cookies.get(LOCALE_KEY)
  return locale ? normalizeLocale(locale) : undefined
}

export const setLocaleToCookie = (locale: string): void => {
  const opts: CookieAttributes = {}

  const { domain } = psl.parse(window.location.hostname) as ParsedDomain
  if (domain) {
    opts.domain = domain
  }

  cookies.set(LOCALE_KEY, locale, opts)
}

export const getLocale = (): string => {
  if (!hasWindow()) {
    // In case of SSR we want to render default
    return defaultLocale
  }

  const localeFromCookie = getLocaleFromCookie()
  if (localeFromCookie && isLocaleSupported(localeFromCookie)) {
    return localeFromCookie
  }

  const browserLocale = getBrowserLocale()
  if (browserLocale && isLocaleSupported(browserLocale)) {
    return browserLocale
  }

  return defaultLocale
}

export const getMessageOrUndef = (intl: IntlFormatters['formatMessage'], id: string): string | undefined => {
  const value = intl({ id, defaultMessage: id })
  return value === id ? undefined : value
}

export const getMessageArray = (
  intl: IntlFormatters['formatMessage'],
  IdPrefix: string,
  IdSuffix?: string | undefined,
  maxIndex: number = 10,
): string[] => {
  const messages = []

  for (let i = 0; i < maxIndex; i++) {
    const id = IdSuffix === undefined ? `${IdPrefix}.${i}` : `${IdPrefix}.${i}.${IdSuffix}`
    const msg = getMessageOrUndef(intl, id)
    msg !== undefined && messages.push(msg)
  }

  return messages
}
