import React from 'react'
import { defaultLocale } from './locales'

export interface LocaleContextType {
  locale: string
  setLocale?: (value: string) => void
}

const LocaleContext = React.createContext<LocaleContextType>({ locale: defaultLocale })
export default LocaleContext
